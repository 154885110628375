import api from '@flatfile/api'

async function transformSheetsToObject(workbookId) {
	const wb = await api.workbooks.get(workbookId)
	const sheets = wb.data.sheets
	const sheetRows = await sheets.reduce(async (acc, sheet) => {
		const resolvedAcc = await acc
		const records = await api.records.get(sheet.id)

		return {
			...resolvedAcc,
			[sheet.config.slug]: records.data.records,
		}
	}, {})

	return sheetRows
}

export default transformSheetsToObject
