import { get } from 'lodash'

function provalDataSerializer(rows) {
	if (!rows)
		return {
			proval_data_cash: {},
			proval_data_implicit_subsidy: {},
			proval_data_total: {},
		}

	const values = rows.reduce((obj, row) => {
		const { field, cashResults, implicitSubsidyResults, totalResults } =
			row.values

		obj.cash = {
			[`${field.value}`]: cashResults.value,
			...obj.cash,
		}
		obj.implicitSubsidy = {
			[`${field.value}`]: implicitSubsidyResults.value,
			...obj.implicitSubsidy,
		}
		obj.total = {
			[`${field.value}`]: totalResults.value,
			...obj.total,
		}

		return obj
	}, {})

	return {
		proval_data_cash: values.cash,
		proval_data_implicit_subsidy: values.implicitSubsidy,
		proval_data_total: values.total,
	}
}

function gainLossSerializer(rows) {
	if (!rows) return {}

	const obj = rows.reduce((record, row) => {
		if (!row.values.field.value) return record

		const { field, value } = row.values
		record[field.value] = value.value
		return record
	}, {})

	return obj
}

function projectedBenefitPaymentsDataSerializer(rows) {
	if (!rows) return []

	return rows.map((row) => {
		const { year, total, active, inactive } = row.values

		return {
			year: year.value,
			total: total.value,
			active: active.value,
			inactive: inactive.value,
		}
	}, [])
}

export function opebValuationReportDataSerializer(sheetRows) {
	return {
		gain_loss: gainLossSerializer(get(sheetRows, 'gain-loss')),
		projected_benefit_payments_cash: projectedBenefitPaymentsDataSerializer(
			get(sheetRows, 'pvb-cash'),
		),
		projected_benefit_payments_implicit_subsidy:
			projectedBenefitPaymentsDataSerializer(
				get(sheetRows, 'pvb-implicit-subsidy'),
			),
		projected_benefit_payments_total: projectedBenefitPaymentsDataSerializer(
			get(sheetRows, 'pvb-total'),
		),
		...provalDataSerializer(get(sheetRows, 'proval-data')),
	}
}

export default opebValuationReportDataSerializer
