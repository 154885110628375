import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const propTypes = {
	value: PropTypes.string,
}

const defaultProps = {}

function DateTimeRenderer({ value }) {
	if (!value) return
	return <span>{moment(value).format('L LTS')}</span>
}

DateTimeRenderer.propTypes = propTypes
DateTimeRenderer.defaultProps = defaultProps

export default memo(DateTimeRenderer)
