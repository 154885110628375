import { FlatfileListener } from '@flatfile/listener'
import { recordHook } from '@flatfile/plugin-record-hook'
import {
	completeJob,
	failJob,
	financialDataFlatfileValidations,
	getRowsAndFieldConfigs,
	checkForValidationErrors,
	handleImportError,
} from 'utils'
import { financialDataSerializer } from 'serializers'
import { createFinancialData } from 'api'

const FINANCIAL_DATA_IMPORT_ERROR =
	'There was an issue importing financial data. Please contact an administrator.'

function financialDataListener(
	planFiscalYearDataId,
	setErrorMessage,
	setShowSpace,
) {
	return FlatfileListener.create((listener) => {
		listener.use(
			recordHook('financial-data', (record) => {
				financialDataFlatfileValidations(record)
				return record
			}),
		)

		listener.filter({ job: 'workbook:financialData:submit' }, (configure) => {
			configure.on('job:ready', async ({ context: { jobId, workbookId } }) => {
				try {
					const { rows } = await getRowsAndFieldConfigs(workbookId)

					if (!(await checkForValidationErrors(rows, jobId))) {
						try {
							const financialDataRecord = financialDataSerializer(rows)
							await createFinancialData(
								financialDataRecord,
								planFiscalYearDataId,
							)

							await completeJob(jobId)
						} catch (importError) {
							await handleImportError(
								jobId,
								importError,
								FINANCIAL_DATA_IMPORT_ERROR,
								setErrorMessage,
								setShowSpace,
							)
						}
					}
				} catch (error) {
					await failJob(jobId)
				}
			})
		})
	})
}

export default financialDataListener
