import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { initializeFlatfile } from '@flatfile/react'
import { ErrorMessage } from 'components'
import { valuationReportDataListener } from 'listeners'
import { deleteSpace } from 'api'

const propTypes = {
	flatfileConfig: PropTypes.object.isRequired,
	flatfileOperation: PropTypes.string.isRequired,
	workbook: PropTypes.object.isRequired,
	planFiscalYearDataId: PropTypes.number.isRequired,
}

const defaultProps = {}

async function handleClose(spaceId, setShowSpace, setErrorMessage) {
	try {
		await deleteSpace(spaceId)
		setShowSpace(false)
		window.location.reload()
	} catch (e) {
		setErrorMessage(
			'There was an issue exiting. Please contact an administrator.',
		)
	}
}

function ImportValuationReportDataButton({
	flatfileConfig,
	flatfileOperation,
	workbook,
	planFiscalYearDataId,
}) {
	const [showSpace, setShowSpace] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const { Space, OpenEmbed } = initializeFlatfile({
		...flatfileConfig,
		workbook,
		listener: valuationReportDataListener(
			planFiscalYearDataId,
			setErrorMessage,
			setShowSpace,
		),
		sidebarConfig: {
			showSidebar: true,
		},
		closeSpace: {
			operation: flatfileOperation,
			onClose: () => {
				const spaceId = Space().props?.spaceId
				handleClose(spaceId, setShowSpace, setErrorMessage)
			},
		},
	})

	const onOpenSpace = async () => {
		setShowSpace(!showSpace)
		await OpenEmbed()
	}

	return (
		<>
			{errorMessage && (
				<ErrorMessage
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}
			<button
				className="button-primary button-small import"
				onClick={onOpenSpace}
			>
				{showSpace ? 'Close' : 'Import ProVal Results'}
			</button>
			{showSpace && <Space />}
		</>
	)
}

ImportValuationReportDataButton.propTypes = propTypes
ImportValuationReportDataButton.defaultProps = defaultProps

export default memo(ImportValuationReportDataButton)
