import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { initializeFlatfile } from '@flatfile/react'
import { deleteSpace } from '../services/api'
import { historicalValuationDataListener } from 'listeners'
import ErrorMessage from './ErrorMessage'

const propTypes = {
	flatfileConfig: PropTypes.object.isRequired,
	workbook: PropTypes.object.isRequired,
	planId: PropTypes.number.isRequired,
}

const defaultProps = {}

async function handleClose(spaceId, setShowSpace, setErrorMessage) {
	try {
		await deleteSpace(spaceId)
		setShowSpace(false)
		window.location.reload()
	} catch (e) {
		setErrorMessage(
			'There was an issue exiting. Please contact an administrator.',
		)
	}
}

function ImportHistoricalValuationDataButton({
	flatfileConfig,
	workbook,
	planId,
}) {
	const [showSpace, setShowSpace] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const { Space, OpenEmbed } = initializeFlatfile({
		...flatfileConfig,
		workbook,
		listener: historicalValuationDataListener(
			planId,
			setErrorMessage,
			setShowSpace,
		),
		sidebarConfig: {
			showSidebar: false,
		},
		closeSpace: {
			operation: 'valuationDataHistory:submit',
			onClose: () => {
				const spaceId = Space().props?.spaceId
				handleClose(spaceId, setShowSpace, setErrorMessage)
			},
		},
	})

	const onOpenSpace = async () => {
		setShowSpace(!showSpace)
		await OpenEmbed()
	}

	return (
		<>
			{errorMessage && (
				<ErrorMessage
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}
			<button
				className="button-primary button-small import"
				onClick={onOpenSpace}
			>
				{showSpace ? 'Close' : 'Import Historical Valuation Data'}
			</button>
			{showSpace && <Space />}
		</>
	)
}

ImportHistoricalValuationDataButton.propTypes = propTypes
ImportHistoricalValuationDataButton.defaultProps = defaultProps

export default memo(ImportHistoricalValuationDataButton)
