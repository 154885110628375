import { get } from 'lodash'

function provalMatchDataSerializer(rows) {
	if (!rows) return {}

	const dropKeys = [
		'Number Inactive (Retired)',
		'Annuities Inforce (Retired)',
		'PV of Future Benefits (Inactive) (Retired)',
		'Inactive Average Age (Retired)',
	]
	// nvt = Non-Vested Terminated
	const nvtKeys = [
		'Number Inactive (Vested)',
		'PV of Future Benefits (Inactive) (Vested)',
	]

	return rows.reduce((obj, row) => {
		const { field, results, resultsSubtotalOne, resultsSubtotalTwo } =
			row.values
		const key = field.value
		obj[key] = results.value
		if (dropKeys.includes(key)) {
			obj[key.replace('Retired', 'DROP')] = resultsSubtotalOne.value
		} else if (nvtKeys.includes(key)) {
			obj[`${key} (DROP)`] = resultsSubtotalTwo.value
			obj[`${key} (No DROP)`] = resultsSubtotalOne.value
		}
		return obj
	}, {})
}

function projectedBenefitPaymentsSerializer(rows) {
	if (!rows)
		return {
			projected_total_benefit_payments: {},
			projected_active_benefit_payments: {},
		}

	const payments = rows.reduce((obj, row) => {
		const {
			year,
			projectedActiveBenefitPayments,
			projectedTotalBenefitPayments,
		} = row.values
		if (Number.isInteger(year.value)) {
			obj.active = {
				[`${year.value}`]: projectedActiveBenefitPayments.value,
				...obj.active,
			}
			obj.total = {
				[`${year.value}`]: projectedTotalBenefitPayments.value,
				...obj.total,
			}
		}
		return obj
	}, {})

	return {
		projected_total_benefit_payments: payments.total,
		projected_active_benefit_payments: payments.active,
	}
}

function refundOutputSerializer(rows) {
	if (!rows) return {}

	return rows.reduce((obj, row) => {
		const { field, results } = row.values
		obj[field.value] = results.value
		return obj
	}, {})
}

function gainLossSerializer(rows) {
	if (!rows) return {}

	const obj = rows.reduce((record, row) => {
		if (!row.values.key.value) return record

		const { field, value } = row.values
		record[field.value] = value.value
		return record
	}, {})

	return obj
}

function ldromSerializer(rows) {
	if (!rows) return {}

	return rows.reduce((obj, row) => {
		const { field, results } = row.values
		obj[field.value] = results.value
		return obj
	}, {})
}

function michiganUniformAssumptionsSerializer(rows) {
	if (!rows) return {}

	return rows.reduce((obj, row) => {
		const { field, results } = row.values
		obj[field.value] = results.value
		return obj
	}, {})
}

export function pensionValuationReportDataSerializer(sheetRows) {
	const projectedBenefitPaymentsData = projectedBenefitPaymentsSerializer(
		get(sheetRows, 'projected-benefit-payments'),
	)

	return {
		current_proval_match_data: provalMatchDataSerializer(
			get(sheetRows, 'current-proval-match-data'),
		),
		old_assumptions_proval_match_data: provalMatchDataSerializer(
			get(sheetRows, 'old-assumptions-proval-match-data'),
		),
		current_refund_output: refundOutputSerializer(
			get(sheetRows, 'current-refund-output'),
		),
		old_assumptions_refund_output: refundOutputSerializer(
			get(sheetRows, 'old-assumptions-refund-output'),
		),
		gain_loss: gainLossSerializer(get(sheetRows, 'gain-loss')),
		ldrom: ldromSerializer(get(sheetRows, 'ldrom')),
		michigan_uniform_assumptions: michiganUniformAssumptionsSerializer(
			get(sheetRows, 'michigan-uniform-assumptions'),
		),
		...projectedBenefitPaymentsData,
	}
}

export default pensionValuationReportDataSerializer
