import { FlatfileListener } from '@flatfile/listener'
import {
	pensionValuationReportDataSerializer,
	opebValuationReportDataSerializer,
} from 'serializers'
import {
	completeJob,
	failJob,
	handleImportError,
	transformSheetsToObject,
} from 'utils'
import { updateValuationReport } from 'api'

const VALUATION_REPORT_IMPORT_ERROR =
	'There was an issue importing valuation report data. Please contact an administrator.'

function valuationReportDataListener(
	planFiscalYearDataId,
	setErrorMessage,
	setShowSpace,
) {
	return FlatfileListener.create((listener) => {
		listener.filter(
			{ job: 'workbook:pensionValuationReportData:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const sheetRows = await transformSheetsToObject(workbookId)

							try {
								const valuationReportDataRecords =
									pensionValuationReportDataSerializer(sheetRows)
								await updateValuationReport(
									valuationReportDataRecords,
									planFiscalYearDataId,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									VALUATION_REPORT_IMPORT_ERROR,
									setErrorMessage,
									setShowSpace,
								)
							}
						} catch (error) {
							await failJob(jobId)
						}
					},
				)
			},
		)

		listener.filter(
			{ job: 'workbook:opebValuationReportData:submit' },
			(configure) => {
				configure.on(
					'job:ready',
					async ({ context: { jobId, workbookId } }) => {
						try {
							const sheetRows = await transformSheetsToObject(workbookId)

							try {
								const valuationReportDataRecords =
									opebValuationReportDataSerializer(sheetRows)
								await updateValuationReport(
									valuationReportDataRecords,
									planFiscalYearDataId,
								)

								await completeJob(jobId)
							} catch (importError) {
								await handleImportError(
									jobId,
									importError,
									VALUATION_REPORT_IMPORT_ERROR,
									setErrorMessage,
									setShowSpace,
								)
							}
						} catch (error) {
							await failJob(jobId)
						}
					},
				)
			},
		)
	})
}

export default valuationReportDataListener
